import { signIn } from "next-auth/react";
import {
  type GetServerSideProps,
  type InferGetServerSidePropsType,
} from "next/types";
import { useEffect } from "react";
import { getServerAuthSession } from "~/server/auth";
import { Spinner } from "~/components/ui/spinner";

export default function Home({
  error,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  useEffect(() => {
    void signIn("keycloak");
  }, []);

  return (
    <div className="flex min-h-full flex-col items-center justify-center px-6 py-12 lg:px-8">
      <Spinner />
      {error ? error : ""}
    </div>
  );
}

export const getServerSideProps: GetServerSideProps<{
  error?: string;
}> = async ({ req, res }) => {
  const session = await getServerAuthSession({ req, res });

  if (session) {
    return {
      redirect: {
        destination: "/app",
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};
